/* eslint-disable no-useless-escape */

export enum ResourceType {
  USER = 'user',
  HYPELIST = 'hypelist',
  COMMENT = 'comment',
  REPLY = 'reply',
}

export enum ReportState {
  OK = 'ok',
  BLOCKED = 'blocked',
  SUSPENDED = 'suspended',
  REVIEWING = 'reviewing',
}

export const statuses = {
  OK: {
    value: 'ok',
    label: 'Ok',
  },
  REPORTED: {
    value: 'reported',
    label: 'Reported',
  },
  BLOCKED: {
    value: 'blocked',
    label: 'Blocked',
  },
};

export const phoneRegexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
