import { ReportState } from './constants';

const getStatusColor = (status: ReportState) => {
  switch (status) {
    case ReportState.REVIEWING:
      return 'blue';
    case ReportState.OK:
      return 'green';
    case ReportState.BLOCKED:
      return 'red';
    case ReportState.SUSPENDED:
      return 'orange';
    default:
      return 'blue';
  }
};

export { getStatusColor };
