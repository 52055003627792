import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Descriptions, List, Row, Tooltip, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { setLoader } from "../../store/actions/mainActions";
import { IUser } from "../../types";
import {
  getReferredUsersByUser,
  getUserById,
  updateUser,
} from "../../utils/api";
import parseErrors from "../../utils/parseErrors";
import Checkbox from "antd/es/checkbox/Checkbox";

const User = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const [referredUsers, setReferredUsers] = useState<IUser[]>([]);
  const [userData, setUserData] = useState<IUser>({
    email: "",
    updatedAt: "",
    notifications: false,
    createdAt: "",
    bio: "",
    username: "",
    socialNetworks: {
      instagram: "",
      tiktok: "",
    },
    id: "",
    token: "",
    invitation: null,
    photoURL: "",
    displayName: "User",
    hypelists: 0,
    hypelistURLs: [],
    verified: false,
    brand: false,
    curator: false,
    referralCode: "",
    numberReferred: 0,
  });
  const [urls, setUrls] = useState<{ id: string; URL: string; Name: string }[]>(
    [],
  );
  const [canEditUsername, setCanEditUsername] = useState(false);
  const [currUsername, setCurrUsername] = useState("");

  useEffect(() => {
    if (userId) {
      getUserData(userId);
      getReferredUsers(userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getUserData = async (id: string) => {
    dispatch(setLoader(true));

    try {
      const user = await getUserById(id);
      setUserData(user.data.data);
      setCurrUsername(user.data.data.username);

      const urls = [];
      for (const url of user.data?.data?.hypelistURLs || []) {
        const arr = url.URL.split("/");
        const id = arr[arr.length - 1] ?? "";
        urls.push({
          id,
          URL: url.URL,
          Name: url.Name,
        });
      }
      setUrls(urls);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const getReferredUsers = async (id: string) => {
    dispatch(setLoader(true));

    try {
      const users = await getReferredUsersByUser(id);
      setReferredUsers(users.data.data);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const renderSocialNetworks = () => {
    if (!userData.socialNetworks) {
      return null;
    }
    const arr = Object.keys(userData.socialNetworks) ?? [];

    return arr.map((item) => (
      <Descriptions.Item label={item} key={item}>
        {item === "tiktok" ? (
          <a
            href={`https://www.tiktok.com/@${userData.socialNetworks[item as "instagram" | "tiktok"]}`}
            target="_blank"
            rel="noreferrer"
          >
            {userData.socialNetworks[item as "instagram" | "tiktok"]}
          </a>
        ) : item === "instagram" ? (
          <a
            href={`https://www.instagram.com/${userData.socialNetworks[item as "instagram" | "tiktok"]}`}
            target="_blank"
            rel="noreferrer"
          >
            {userData.socialNetworks[item as "instagram" | "tiktok"]}
          </a>
        ) : (
          userData.socialNetworks[item as "instagram" | "tiktok"]
        )}
      </Descriptions.Item>
    ));
  };

  const goBack = () => {
    navigate(-1);
  };

  const saveVerifiedStatus = async (status: boolean) => {
    try {
      userData.verified = status;
      const user = await updateUser(userData.id, userData);
      setUserData(user.data.data);
      setCurrUsername(user.data.data.username);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }
  };

  const saveUsername = async (val: string) => {
    try {
      const user = await updateUser(userData.id, {
        ...userData,
        username: val,
      });
      setUserData(user.data.data);
      setCurrUsername(user.data.data.username);
      setCanEditUsername(false);
      messageContext.instance?.open({
        type: "success",
        content: "Username updated successfully!",
      });
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }
  };

  const saveBrandVerifiedStatus = async (status: boolean) => {
    try {
      userData.brand = status;
      const user = await updateUser(userData.id, userData);
      setUserData(user.data.data);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }
  };

  const saveCuratorStatus = async (status: boolean) => {
    try {
      userData.curator = status;
      const user = await updateUser(userData.id, userData);
      setUserData(user.data.data);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }
  };

  return (
    <div className="user">
      <Row className="title">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>

        {userData.photoURL && (
          <ModalImage
            small={userData.photoURL}
            large={userData.photoURL}
            hideDownload={true}
            hideZoom={true}
            showRotate={false}
            alt="User avatar"
            className="preview"
          />
        )}
        <h1>{userData.displayName}</h1>
      </Row>
      <Row>
        <Descriptions layout="vertical">
          <Descriptions.Item
            label={
              <span>
                Username&nbsp;&nbsp;&nbsp;
                <EditOutlined
                  onClick={() => setCanEditUsername(!canEditUsername)}
                />
              </span>
            }
          >
            {canEditUsername ? (
              <Input
                value={currUsername}
                style={{ width: "250px" }}
                maxLength={30}
                allowClear={true}
                onPressEnter={() => saveUsername(currUsername)}
                onKeyDownCapture={(evt) => {
                  // Switch back to the label on clicking Escape key
                  if (evt.key === "Escape") {
                    setCurrUsername(userData.username);
                    setCanEditUsername(false);
                  }
                }}
                onChange={(evt) => {
                  // Skip adding space to the username
                  const re = /^[A-Za-z][A-Za-z0-9]*$/;
                  const val = evt.target.value;
                  if (val === "" || re.test(val)) {
                    setCurrUsername(val);
                  }
                }}
              />
            ) : (
              userData.username
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{userData.email}</Descriptions.Item>
          {renderSocialNetworks()}
          <Descriptions.Item label="Created At">
            {userData.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {userData.updatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Verified">
            <Checkbox
              onChange={(evt) => saveVerifiedStatus(evt.target.checked)}
              checked={userData.verified}
            ></Checkbox>
          </Descriptions.Item>
          <Descriptions.Item label="referralCode">
            {userData.referralCode}
          </Descriptions.Item>
          <Descriptions.Item label="numberReferred">
            {userData.numberReferred}
          </Descriptions.Item>
          <Descriptions.Item label="Brand">
            <Checkbox
              onChange={(evt) => saveBrandVerifiedStatus(evt.target.checked)}
              checked={userData.brand}
            ></Checkbox>
          </Descriptions.Item>
          <Descriptions.Item label="Curator">
            <Checkbox
              onChange={(evt) => saveCuratorStatus(evt.target.checked)}
              checked={userData.curator}
            ></Checkbox>
          </Descriptions.Item>
        </Descriptions>
      </Row>

      <h2>Hypelists</h2>

      <Row>
        <List
          itemLayout="horizontal"
          dataSource={urls}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <Link
                    to={`/hypelist/${item.id}`}
                    state={{ url: item.URL, userId: userData.id }}
                  >
                    {item.Name}
                  </Link>
                }
              />
            </List.Item>
          )}
        />
      </Row>
      <h2>Referred Users</h2>

      <Row>
        <List
          itemLayout="horizontal"
          dataSource={referredUsers}
          renderItem={(item) => (
            <List.Item key={item.id}>
              {item.displayName} - {item.username} - {item.id} -{" "}
              {item.createdAt}
            </List.Item>
          )}
        />
      </Row>
    </div>
  );
};

export default User;
