import { Row, Table } from 'antd';
import { useState } from 'react';
import { columns } from './constants';

import { useReportService } from '../../hooks';
import { IReportedUser } from '../../types';
import { ReportState, ResourceType } from '../../utils/constants';
import { UpdateStatusModal } from './UpdateStatusModal';

const ReportedUsers = () => {
  const [modalData, setModalData] = useState<{
    user: IReportedUser | null;
    isEditing: boolean;
    isOpen: boolean;
    status: ReportState | null;
  }>({
    user: null,
    isEditing: false,
    isOpen: false,
    status: null,
  });

  const { isLoading, reportedList, pagination, onPaginationChange, updateReportStatus } =
    useReportService<IReportedUser>(ResourceType.USER);

  const handleCancel = () => {
    setModalData({
      user: null,
      isEditing: false,
      isOpen: false,
      status: null,
    });
  };

  const handleRowClick = (record: IReportedUser) => {
    setModalData({
      user: record,
      isEditing: false,
      isOpen: true,
      status: record.status,
    });
  };

  const handleEdit = () => {
    setModalData((prev) => ({
      ...prev,
      isEditing: true,
    }));
  };

  const handleStatusChange = (newStatus: ReportState) => {
    setModalData((prev) => ({
      ...prev,
      status: newStatus,
    }));
  };

  const handleUpdateStatus = async () => {
    if (modalData.user && modalData.status) {
      await updateReportStatus(modalData.user.resource_uid, modalData.user.resource_type, modalData.status);
      handleCancel();
    }
  };

  return (
    <>
      <div className="reported-users">
        <Row>
          <h1>Reported Users</h1>
        </Row>

        <Row>
          <Table
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowKey="uid"
            columns={columns}
            dataSource={reportedList}
            pagination={{
              current: pagination.currentPage,
              total: pagination.total,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
            loading={isLoading}
          />
        </Row>
      </div>
      <UpdateStatusModal
        modalData={modalData}
        handleUpdateStatus={handleUpdateStatus}
        handleStatusChange={handleStatusChange}
        handleCancel={handleCancel}
        handleEdit={handleEdit}
        isLoading={isLoading}
      />
    </>
  );
};

export default ReportedUsers;
