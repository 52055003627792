import { LeftOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Descriptions, List, Row, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MessageContext } from '../../context/messageContext';
import { setLoader } from '../../store/actions/mainActions';
import { IHypelist } from '../../types';
import { getHypelist, getReportedHypelist } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import ModalImage from 'react-modal-image';
import StatusLabel from '../../components/StatusLabel';
import ChangeStatusModal from '../../components/ChangeStatusModal';

const Hypelist = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [hypelist, setHypelist] = useState<IHypelist>({
    cover: {
      image: '',
    },
    createdAt: '',
    status: 'ok',
    isPublic: false,
    id: '',
    userId: '',
    title: id ?? '',
    items: [],
    shareLink: '',
    openAIStatus: '',
    updatedAt: '',
    user: {
      id: '',
      bio: '',
      notifications: false,
      updatedAt: '',
      photoURL: '',
      displayName: '',
      createdAt: '',
      username: '',
      socialNetworks: {
        tiktok: '',
        instagram: '',
      },
      email: '',
      token: '',
      verified: false,
      brand: false,
      curator: false,
      referralCode: '',
      numberReferred: 0,
    },
    collaborators: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.url && !state.userId) {
      return;
    }

    if (state.url && !state.userId) {
      fetchReportedHypelist();
    }

    if (state.url && state.userId) {
      fetchHypelist();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!needRefresh) {
      return;
    }

    if (state.url && !state.userId) {
      fetchReportedHypelist();
    }

    if (state.url && state.userId) {
      fetchHypelist();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);

  const fetchHypelist = async () => {
    if (!state.url || !state.userId) {
      return;
    }

    dispatch(setLoader(true));

    try {
      const hypelist = await getHypelist(state.userId, state.url);
      setHypelist(hypelist.data.data);
      setNeedRefresh(false);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const fetchReportedHypelist = async () => {
    dispatch(setLoader(true));

    try {
      const hypelist = await getReportedHypelist(state.url);
      setHypelist(hypelist.data.data);
      setNeedRefresh(false);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="hypelist">
      <Row className="hypelist__topbar">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>
        <h1>{hypelist.title}</h1>

        <div className="hypelist__actions">
          <ChangeStatusModal hypelistId={hypelist.id} status={hypelist.status} setNeedRefresh={setNeedRefresh} />
        </div>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <div className="hypelist__photo">
            {hypelist.cover?.image && (
              <ModalImage
                small={hypelist.cover.image}
                large={hypelist.cover.image}
                hideDownload={true}
                hideZoom={true}
                showRotate={false}
                alt="Hypelist cover"
              />
            )}
          </div>
        </Col>
        <Col span={12}>
          <Descriptions layout="vertical" column={2}>
            <Descriptions.Item label="ID">{hypelist.id}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <StatusLabel text={hypelist.status || 'ok'} />
            </Descriptions.Item>
            <Descriptions.Item label="Created user">{hypelist.user.displayName}</Descriptions.Item>
            <Descriptions.Item label="Created user ID">{hypelist.user.id}</Descriptions.Item>
            <Descriptions.Item label="Is public">{hypelist.isPublic ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Open AI status">{hypelist.openAIStatus}</Descriptions.Item>
            <Descriptions.Item label="Created at">{hypelist.createdAt}</Descriptions.Item>
            <Descriptions.Item label="Updated at">{hypelist.updatedAt}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <h3>Collaborators</h3>
      <Row>
        <List
          itemLayout="horizontal"
          dataSource={hypelist.collaborators}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={48} src={`${item.photoURL}`} />}
                title={<Link to={`/users/${item.id}`}>{item.displayName}</Link>}
                description={item.id}
              />
            </List.Item>
          )}
        />
      </Row>

      <h3>List</h3>
      <Row>
        <List
          itemLayout="horizontal"
          dataSource={hypelist.items ?? []}
          renderItem={(item) => (
            <List.Item extra={<p></p>}>
              <List.Item.Meta
                avatar={
                  <ModalImage
                    className="preview"
                    small={item.image}
                    large={item.image}
                    hideDownload={true}
                    hideZoom={true}
                    showRotate={false}
                    alt="hypelist item"
                  />
                }
                title={item.title}
                description={(item.categoryId?.title || '') + '  ' + item.subtitle}
              />
              {item.location && (
                <List.Item.Meta
                  description={
                    <a
                      href={`http://maps.google.com/maps?q=${item.location.lat},${item.location.lng}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item.location.name}, {item.location.address}
                    </a>
                  }
                />
              )}
            </List.Item>
          )}
        />
      </Row>
    </div>
  );
};

export default Hypelist;
