import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import {  useState } from "react";
import HypelistSearchBar from "../../components/HypelistSearchBar";

interface hypelistSelection {
  id: string;
  name: string;
}

export default function CreateCuratedList({
    handleCreateCuratedList
}:{
    handleCreateCuratedList: (title: string, uids: string[]) => void;
}) {
  const [open, setOpen] = useState(false);
  const [hypelistResults, setHypelistResults] = useState<hypelistSelection[]>([])

  const onHypelistSelect = (id: any, name: any) => {
    const selectedHypelists = hypelistResults.concat([{id, name}])
    setHypelistResults(selectedHypelists)
  }

  const remove = (indexToRemove: number) => {
    const removedArr = hypelistResults.filter((item, index) => index !== indexToRemove);
    setHypelistResults(removedArr);
  }

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    handleCreateCuratedList(values.title, hypelistResults.map(item => item.id))
    setOpen(false);
  };

  const onFinishFailed = () => {
    setOpen(false);
    alert("Missing fields");
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusCircleOutlined />
        New Curated List
      </Button>
      <Modal
        title="Create Curated List"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <br/>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            rules={[{ required: true, message: "Please input list title" }]}
          >
            <Input placeholder="Curated List Title" />
          </Form.Item>
          <div>Search for hypelists to add to this curated list</div>
          <HypelistSearchBar onSelect={onHypelistSelect} />
          <br/>
          {hypelistResults.length > 0 && hypelistResults.map((hypelist, index) => {
            return (
                <div style={{display: "flex"}} key={hypelist.id}>
                    <div style={{marginRight: "5px"}}>{hypelist.name}</div>
                    <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(index)}
                      />
                </div>
            )
            })}
          <br />
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={hypelistResults.length === 0}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
