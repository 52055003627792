import { Button, Row, Table, Popconfirm } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import parseErrors from "../../utils/parseErrors";
import { IChatPrompts } from "../../types";
import { getAllChatTemplates, deleteChatTemplate } from "../../utils/api";

const ChatTemplates = () => {
    const navigate = useNavigate();
    const messageContext = useContext(MessageContext);
    const [templatesList, setTemplatesList] = useState<IChatPrompts[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = async (uid: string) => {
        try {
            await deleteChatTemplate(uid);
            messageContext.instance?.open({
                type: "success",
                content: "Template deleted successfully",
            });
            await fetchTemplates();
        } catch (error: any) {
            const errors = parseErrors(error.response?.data?.error);
            errors.forEach((err: string) => {
                messageContext.instance?.open({
                    type: "error",
                    content: err,
                });
            });
        }
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Prompt",
            dataIndex: "prompt",
            key: "prompt",
        },
        {
            title: "",
            key: "actions",
            render: (text: string, record: IChatPrompts) => (
                <Popconfirm
                    title="Are you sure you want to delete this template?"
                    onConfirm={() => handleDelete(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger icon={<DeleteOutlined />}>
                        
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    useEffect(() => {
        fetchTemplates();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTemplates = async () => {
        setIsLoading(true);

        try {
            const templates = await getAllChatTemplates();
            setTemplatesList(templates.data?.data);
        } catch (error: any) {
          const errors = parseErrors(error.response?.data?.error);

          for (let i = 0; i < errors.length; i += 1) {
              messageContext.instance?.open({
              type: "error",
              content: errors[i],
              });
          }
        }

        setIsLoading(false);
    };

    const addChatTemplates = () => {
        navigate(`/templates/add`);
    };

    return (
      <div className="users">
        <Row>
          <h1>Prompt Templates</h1>
        </Row>
        <Row>
          <Button type="primary" onClick={addChatTemplates}>
            <PlusCircleOutlined />
            Add
          </Button>
        </Row>
        <Row>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={templatesList}
            loading={isLoading}
            pagination={false}
          />
        </Row>
      </div>
    );
}

export default ChatTemplates;
