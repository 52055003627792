import type { FC } from 'react';
import { Button, Modal, Select, Tag } from 'antd';
import { ReportState } from '../../../utils/constants';
import { IReportedUser } from '../../../types';
import { getStatusColor } from '../../../utils/statusColors';

interface IUpdateStatusModalProps {
  modalData: {
    user: IReportedUser | null;
    isEditing: boolean;
    isOpen: boolean;
    status: ReportState | null;
  };
  isLoading: boolean;
  handleCancel: () => void;
  handleEdit: () => void;
  handleStatusChange: (newStatus: ReportState) => void;
  handleUpdateStatus: () => void;
}

const UpdateStatusModal: FC<IUpdateStatusModalProps> = ({
  modalData,
  isLoading,
  handleUpdateStatus,
  handleStatusChange,
  handleEdit,
  handleCancel,
}) => {
  return (
    <Modal
      title="Review User Report"
      open={modalData.isOpen}
      centered
      confirmLoading={isLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        !modalData.isEditing ? (
          <Button key="edit" type="primary" onClick={handleEdit}>
            Edit
          </Button>
        ) : (
          <Button key="save" type="primary" onClick={handleUpdateStatus}>
            Save
          </Button>
        ),
      ]}
    >
      {modalData.user && (
        <div className="reported-users__details">
          <p>
            <strong>User UID:</strong> {modalData.user.resource_uid}
          </p>
          <p>
            <strong>Reason:</strong> {modalData.user.reason}
          </p>
          {modalData.isEditing ? (
            <div className="reported-users__select">
              <p>
                <strong>Status:</strong>
              </p>
              <Select value={modalData.status} onChange={handleStatusChange} style={{ width: '120px' }}>
                {Object.values(ReportState).map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : (
            <p>
              <strong>Status:</strong>
              {modalData.status && <Tag color={getStatusColor(modalData.status)}>{modalData.status}</Tag>}
            </p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default UpdateStatusModal;
