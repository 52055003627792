import { ColumnsType } from 'antd/es/table/interface';
import { IReportedUser } from '../../types';
import { ReportState } from '../../utils/constants';
import { Tag } from 'antd';
import { getStatusColor } from '../../utils/statusColors';

const columns: ColumnsType<IReportedUser> = [
  {
    title: 'Reported User UID',
    dataIndex: 'resource_uid',
    key: 'resource_uid',
  },
  {
    title: 'Reporting User UID',
    dataIndex: 'reporter_uid',
    key: 'reporter_uid',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: ReportState) => <Tag color={getStatusColor(status)}>{status}</Tag>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date: string) =>
      new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (date: string) =>
      new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
  },
];

export { columns };
