import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { getReportList, patchReportStatus } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import { MessageContext } from '../../context/messageContext';
import { ReportState, ResourceType } from '../../utils/constants';

interface IUseReportServiceHookReturn<T> {
  isLoading: boolean;
  reportedList: T[];
  pagination: IPagination;
  onPaginationChange(page: number): void;
  refetch(): Promise<void>;
  updateReportStatus(uid: string, type: ResourceType, status: ReportState): Promise<void>;
}

interface IPagination {
  total: number;
  currentPage: number;
  offset: number;
  rowsPerPage: number;
}

const ROWS_PER_PAGE = 20;

const useReportService = <T>(type: ResourceType): IUseReportServiceHookReturn<T> => {
  const messageContext = useContext(MessageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reportedList, setReportedList] = useState<T[]>([]);
  const [pagination, setPagination] = useState<IPagination>({
    total: 0,
    currentPage: 1,
    offset: 0,
    rowsPerPage: ROWS_PER_PAGE,
  });

  const handleError = useCallback(
    (error: any) => {
      const errors = parseErrors(error.response?.data?.error);
      errors.forEach((errorMessage) => {
        messageContext.instance?.open({
          type: 'error',
          content: errorMessage,
        });
      });
    },
    [messageContext.instance],
  );

  const fetchReportedList = useCallback(
    async (offset: number = 0) => {
      setIsLoading(true);

      try {
        const response = await getReportList(offset, ROWS_PER_PAGE, type);
        const { data, total } = response.data;

        setReportedList(data);
        setPagination((prev) => ({
          ...prev,
          total,
          offset,
          currentPage: Math.floor(offset / ROWS_PER_PAGE) + 1,
        }));
      } catch (error: unknown) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [type, handleError],
  );

  const updateReportStatus = useCallback(
    async (uid: string, type: ResourceType, status: ReportState) => {
      setIsLoading(true);

      try {
        await patchReportStatus(uid, type, status);
        await fetchReportedList(pagination.offset);
      } catch (error: unknown) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchReportedList, pagination.offset, handleError],
  );

  // Initial fetch
  useEffect(() => {
    fetchReportedList();
  }, [fetchReportedList]);

  const onPaginationChange = useCallback(
    (page: number) => {
      const newOffset = (page - 1) * ROWS_PER_PAGE;
      fetchReportedList(newOffset);
    },
    [fetchReportedList],
  );

  const paginationState = useMemo(
    () => ({
      ...pagination,
      rowsPerPage: ROWS_PER_PAGE,
    }),
    [pagination],
  );

  return {
    isLoading,
    reportedList,
    pagination: paginationState,
    onPaginationChange,
    refetch: fetchReportedList,
    updateReportStatus,
  };
};

export default useReportService;
