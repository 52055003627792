import { Row as AntRow, Table, TableProps, Image, Button, AutoComplete, Spin, Input, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { MessageContext } from '../../context/messageContext';
import { ICuratedList, IHypelistSnippet } from '../../types';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { reorderCuratedListHypelists, searchAllHypelists } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import { useDebounce } from 'use-debounce';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../store/actions/mainActions';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const CuratedListTable = ({
  curatedList,
  handleMoveCuratedList,
  handleDeleteCuratedList,
  handleSaveItemOrder,
  handleDeleteItemFromCuratedList,
  handleToggleListVisibility,
  handleEditCuratedListTitle,
  handleAddHypelistToCuratedList,
}: {
  curatedList: ICuratedList;
  handleMoveCuratedList: (id: string, ordering: number) => void;
  handleDeleteCuratedList: (id: string) => void;
  handleSaveItemOrder: (hypelistId: string, curatedListId: string, ordering: number) => void;
  handleDeleteItemFromCuratedList: (hypelistId: string, curatedListId: string) => void;
  handleToggleListVisibility: (id: string, visibility: boolean) => void;
  handleEditCuratedListTitle: (curatedListId: string, title: string) => void;
  handleAddHypelistToCuratedList: (hypelistId: string, curatedListId: string) => void;
}) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [curatedListEditTitle, setCuratedListEditTitle] = useState(curatedList.title);
  const [showEdit, setShowEdit] = useState(false);
  const [hypelistResults, setHypelistResults] = useState<{ value: string; label: string }[]>([]);
  const messageContext = useContext(MessageContext);
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue] = useDebounce(inputValue, 800);
  const [dataSource, setDataSource] = useState(curatedList.hypelists);
  const dispatch = useDispatch();

  const handleInputChange = (value: any) => {
    setInputValue(value);
  };

  const onSelect = (data: any, options: any) => {
    handleAddHypelistToCuratedList(options.value, curatedList.id);
  };

  const handleOnChange = (e: any) => {
    console.log(e.target.value);
    setCuratedListEditTitle(e.target.value);
  };

  const searchHypelists = async (value: string) => {
    if (!value) {
      return;
    }
    if (value.length < 3) {
      return;
    }
    if (searchLoading) {
      return;
    }
    try {
      setSearchLoading(true);
      const allHypelists = await searchAllHypelists(value);
      setHypelistResults(
        allHypelists.data.data.map((h: { id: any; title: any; userName: string }) => {
          return {
            value: h.id,
            label: `${h.title} by ${h.userName}`,
          };
        }),
      );
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const updatedDataSource = arrayMove(previous, activeIndex, overIndex);
        updateOrdering(updatedDataSource.map((h) => h.id));
        return updatedDataSource;
      });
    }
  };

  const updateOrdering = async (orderingIds: string[]) => {
    dispatch(setLoader(true));
    await reorderCuratedListHypelists(orderingIds, curatedList.id);
    // const updatedCuratedList = await getCuratedList(curatedList.id);
    // setDataSource(updatedCuratedList.data.data.hypelists);
    dispatch(setLoader(false));
  };

  useEffect(() => {
    searchHypelists(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  const columns: TableProps<IHypelistSnippet>['columns'] = [
    {
      key: 'sort',
    },
    {
      title: 'Ordering',
      key: 'ordering',
      render: (_, record) => (
        <AntRow>
          <Button
            type="primary"
            ghost
            onClick={() => handleSaveItemOrder(record.id, curatedList.id, record.ordering - 1)}
          >
            <ArrowUpOutlined />
          </Button>
          &nbsp;&nbsp;
          <Button
            type="primary"
            ghost
            onClick={() => handleSaveItemOrder(record.id, curatedList.id, record.ordering + 1)}
          >
            <ArrowDownOutlined />
          </Button>
        </AntRow>
      ),
    },
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (cover) => <Image width={65} src={cover.image || ''} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <div>{id}</div>,
    },
    {
      title: 'Length of list',
      dataIndex: 'itemsLength',
      key: 'itemsLength',
      render: (num) => <div>{num}</div>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <AntRow>
          <Button type="primary" danger onClick={() => handleDeleteItemFromCuratedList(record.id, curatedList.id)}>
            <DeleteOutlined />
          </Button>
        </AntRow>
      ),
    },
  ];

  return (
    <>
      <AntRow>
        {showEdit ? (
          <Space.Compact style={{ width: '33%' }}>
            <Input value={curatedListEditTitle} onChange={handleOnChange} />
            <Button type="primary" onClick={() => handleEditCuratedListTitle(curatedList.id, curatedListEditTitle)}>
              Save
            </Button>
          </Space.Compact>
        ) : (
          <h3>{curatedList.title} &nbsp;</h3>
        )}
        {!showEdit && (
          <Button type="primary" size="small" ghost onClick={() => setShowEdit(true)}>
            <EditOutlined />
          </Button>
        )}
        &nbsp;&nbsp;&nbsp;
        <Button type="primary" danger onClick={() => handleDeleteCuratedList(curatedList.id)}>
          <DeleteOutlined />
          Delete
        </Button>
        &nbsp;&nbsp;
        <Button
          type={curatedList.visible ? 'primary' : 'dashed'}
          onClick={() => handleToggleListVisibility(curatedList.id, !curatedList.visible)}
        >
          {curatedList.visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          {curatedList.visible ? 'Hide' : 'Show'}
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={() => handleMoveCuratedList(curatedList.id, curatedList.ordering - 1)}>
          <ArrowUpOutlined />
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={() => handleMoveCuratedList(curatedList.id, curatedList.ordering + 1)}>
          <ArrowDownOutlined />
        </Button>
        &nbsp;&nbsp;
        <AutoComplete
          allowClear
          disabled={searchLoading}
          options={hypelistResults}
          style={{ width: 200 }}
          onSelect={onSelect}
          onChange={handleInputChange}
          placeholder="Add a hypelist..."
        />
        {searchLoading && <Spin />}
      </AntRow>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="id"
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns}
            dataSource={dataSource}
            size="small"
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default CuratedListTable;
